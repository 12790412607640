<template>
    <div class="animated fadeIn">
    <b-card>
        <div class="cardheading">
            <div class="">
                <h3 class="my-auto">
                    <span class="menu-title text-success">Mehendi- for the Bride</span>
                </h3>
            </div>
        </div>


        <div class="col-12">
            <div class="portlet-title m-3">
                <div class="">
			<span class="">
				Mehendi- for the Bride
			</span>
                </div>
                <div class="tools"> </div>
            </div>
            <div class="portlet-body m-3">
                <div class="row">
                    <div class="col-md-3">
                        <div class="mt-element-ribbon bg-grey-steel">
                            <div class="badge badge-danger text-uppercase mb-3">
                                option
                            </div>
                            <div class="thumbnail mb-5">
                                <img src="https://partner.staging180601.romoni.xyz/images/thumbnail/1539351565.jpg" style="width: 150px;" alt="Mehendi- for the Bride">
                            </div>
                        </div>


                        <div class="romoni-service-detail-info">
                            <div>
                                <span class="font-weight-bold">Service name:</span>
                                <p>Mehendi- for the Bride</p>
                            </div>

                            <div>
                                <span class="font-weight-bold">Category :</span>
                                <p>Bridal Makeup and Mehend</p>
                            </div>
                            <div>
                                <span class="font-weight-bold">Subcategory :</span>
                                <p>None</p>
                            </div>

                        </div>


                    </div>
                    <div class="col-md-9">
                        <div class="mb-4 caption">
                            <h4>Description</h4>
                            <div class="">
                                Design till Wrist. Standard mehendi desigh by salon/ freelance
                                mehendi artist till half wrist. Mehendi artist will provide
                                the cones, unless the customer wants to porvide it themselves.
                            </div>
                        </div>

                        <div class="mb-4 caption">
                            <h4>Options</h4>
                            <div class="">
                                All of our options are.
                                <ol>
                                    <li>First</li>
                                    <li>Second</li>
                                    <li>Third</li>
                                </ol>
                            </div>
                        </div>

                        <div class="mb-4 caption">
                            <h4>Pricing Table</h4>
                            <div class="table-responsive">
                                <table class="table border">
                                    <thead class="bg-success">
                                    <tr>
                                        <th scope="col">Option 1</th>
                                        <th scope="col">Option 2</th>
                                        <th scope="col">Price</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row">
                                            Standard Bridal Design
                                        </th>
                                        <td>
                                            1
                                        </td>
                                        <td>
                                            2200
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            Traditional Bridal Design
                                        </th>
                                        <td>
                                            1
                                        </td>
                                        <td>
                                            2500
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            Gorgeous and Intricate Bridal Design
                                        </th>
                                        <td>
                                            1
                                        </td>
                                        <td>
                                            3500
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div class="mb-4 caption">
                            <h4>FAQS</h4>
                            <div class="">
                                <div class="mt-3">
                                    <div class="alert alert-dark m-0 p-2" role="alert">
                                        Will the beautician bring all the materials?
                                    </div>
                                    <div class="mt-2">
                                        Keep hot water and a big bowl ready before service.
                                        If you want to use your own shampoo, please inform the service provider.
                                        Customer needs to provide hot water if required ·
                                        Customer needs to provide bed/ chair for service execution.
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <div class="alert alert-dark m-0 p-2" role="alert">
                                        Is it safe?
                                    </div>
                                    <div class="mt-2">
                                        The background of all the service providers are
                                        checked before they are verified on our system.
                                        Nobody without valid documents and identification is allowed to
                                        provide service on our platform. The customer is liable for safety and security
                                        of the service provider and of herself/himself and is
                                        advised to keep their valuable things in a safe location.
                                    </div>
                                </div>




                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </b-card>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>
    .badge-success {
        border-radius: .125rem;
        font-size: 12px;
        font-weight: initial;
        line-height: 1;
        padding: .375rem .5625rem;
        font-weight: 600;
    }


    .caption > div {
        margin: 0px;
    }
</style>
